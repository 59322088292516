


























































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Concejo extends Vue {
  private menu = "";
  private menuLateral: any = [];
  private nombreWeb: any = "";
  private listaConcejales = [
    {
      nombre: "Alejandra Salinas Inostroza",
      email: "concejalasalinas@maipu.cl",
      foto: "url_media/Concejo/Salinas.jpg"
    },
    {
      nombre: "Alejandro Almendares Müller",
      email: "concejalalmendares@maipu.cl",
      foto:
        "url_media/Concejo/Almendares.jpg"
    },
    {
      nombre: "Horacio Saavedra Núñez",
      email: "concejalsaavedra@maipu.cl",
      foto:
        "url_media/Concejo/Saavedra.jpg"
    },
    {
      nombre: "Felipe Farías López",
      email: "concejalfarias@maipu.cl",
      foto: "url_media/Concejo/Farias.jpg"
    },
    {
      nombre: "Camila Quiroz Viveros",
      email: "concejalaquiroz@maipu.cl",
      foto: "url_media/Concejo/Quiroz.jpg"
    },
    {
      nombre: "Bladymir Muñoz Acevedo",
      email: "concejalmunoz@maipu.cl",
      foto: "url_media/Concejo/Munoz.jpg"
    },
    {
      nombre: "Gonzalo Ponce Bórquez",
      email: "concejalponce@maipu.cl",
      foto: "url_media/Concejo/Ponce.jpg"
    },
    {
      nombre: "Elizabeth González Muñoz",
      email: "concejalagonzalez@maipu.cl",
      foto:
        "url_media/Concejo/Gonzalez.jpg"
    },
    {
      nombre: "Carolina Silva Nieto",
      email: "concejalasilva@maipu.cl",
      foto: "url_media/Concejo/Silva.jpg"
    },
    {
      nombre: "Graciela Arochas Felber",
      email: "concejalaarochas@maipu.cl",
      foto: "url_media/Concejo/Arochas.jpg"
    }
  ];

  private beforeMount() {}
  private mounted() {
    document.title = "Concejo Municipal - Maipú Sustentable";
    this.setMenu();
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private async setMenu() {
    const runtimeConfig1 = await fetch("/menu_maipu.json");
    this.menu = await runtimeConfig1.json();
    for (let i = 0; i < this.menu.length; i++) {
      this.getSubMenu(this.menu[i], "concejo-municipal");
    }
  }

  private getSubMenu(element: any, nombre_web: string) {
    if (element.nombre_web == nombre_web) {
      return true;
    } else {
      if (element.sub_menu && element.sub_menu.length > 0) {
        for (let j = 0; j < element.sub_menu.length; j++) {
          if (this.getSubMenu(element.sub_menu[j], nombre_web)) {
            this.menuLateral = element.sub_menu;
            return false;
          }
        }
      }
      return false;
    }
  }

  private selectedStyle(nombre_web: string) {
    if ("concejo-municipal" == nombre_web) {
      return " background-color: #10174A !important; color: #f1f1f1 !important;font-family: 'RubikBold' !important;";
    } else {
      return "";
    }
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal") {
      this.routerGo("Concejo");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }

  private sendMail(correo: string) {
    window.open("mailto:" + correo);
  }
}
